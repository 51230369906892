import React from 'react';

import { AboutDetails } from '@home/about/components';

const About = () => {
  return (
    <section>
      <AboutDetails />
    </section>
  );
};
export default About;
